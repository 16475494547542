import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import SEO from '../components/organisms/seo';
import JournalCTA from '../components/modules/cta/journalCta';
import { media } from '../utils/media-queries';
import { LayoutContext } from '../components/organisms/layoutProvider';

const JournalPage = ({ data }) => {
  const { nodes } = data.post;

  const [activeTags, setActiveTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  const { clickedTag } = useContext(LayoutContext);

  useEffect(() => {
    const allGivenTags = nodes
      .map((node) => node.tags.options)
      .reduce((acc, val) => acc.concat(val), []);
    setAvailableTags(
      allGivenTags.filter((tag, i) => allGivenTags.indexOf(tag) === i)
    );

    if (clickedTag) {
      setActiveTags([...activeTags, clickedTag]);
    }
  }, []);

  return (
    <div className="spacing-top-big">
      <SEO siteTitle="Journal" />
      <StyledTags>
        <p className="tagged">Browse by tags</p>
        {availableTags.map((tag, i) => (
          <button
            key={i}
            type="button"
            className={`tag ${activeTags.includes(tag) ? 'tag-active' : ''}`}
            onClick={() =>
              activeTags.includes(tag)
                ? setActiveTags(activeTags.filter((e) => e !== tag))
                : setActiveTags([...activeTags, tag])
            }
          >
            {tag}
          </button>
        ))}
      </StyledTags>
      <AnimatePresence exitBeforeEnter>
        {nodes.map(
          (post, i) =>
            activeTags.every((active) =>
              post.tags.options.includes(active)
            ) && (
              <motion.div
                key={`key${i}`}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.4, ease: [0, 0.55, 0.45, 1] }}
              >
                <JournalCTA
                  isJournalLanding
                  imageSrc={post.previewImage.image.asset.fluid}
                  alt={post.previewImage.alt}
                  headline={post.previewHeadline}
                  tags={post.tags?.options}
                  copy={post.title}
                  slug={post.slug.current}
                  tagSlug={post.slug.current}
                  journalEntry
                  activeTags={activeTags}
                  setActiveTags={setActiveTags}
                />
              </motion.div>
            )
        )}
      </AnimatePresence>
    </div>
  );
};

const StyledTags = styled.div`
  font-size: var(--font-size-0);
  padding-right: var(--v-spacing-M);
  display: flex;
  flex-wrap: wrap;
  color: var(--blue);
  margin-bottom: var(--spacing-XL);

  .tagged {
    padding: 12.5px 25px;
    padding-left: 0;
    margin-bottom: var(--spacing-XXS);
    border-radius: 50px;
    background: white;
  }

  .tag-active {
    color: white;
    background: var(--blue) !important;
  }

  .tag {
    margin-bottom: var(--spacing-XXS);
    margin-right: var(--spacing-XXS);
    border-radius: 50px;
    padding: 12.5px 25px;
    background: rgba(242, 242, 242, 1);

    &:hover {
      transition: background 0.4s;
      background: rgba(242, 242, 242, 0);
    }

    .highlight {
      position: absolute;
      top: -25%;
      left: 0;
      height: 100%;
      width: 100%;
      visibility: visible;
      z-index: -1;
    }
  }

  @media ${media.L} {
    max-width: 40%;
  }
`;

export const query = graphql`
  query Journal {
    post: allSanityJournalPost {
      nodes {
        previewHeadline
        previewImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        tags {
          options
        }
        title
        slug {
          current
        }
      }
    }
  }
`;

JournalPage.propTypes = {
  data: PropTypes.object,
};

export default JournalPage;
